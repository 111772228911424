/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link , useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RegistrationConfirmation: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL || 'api'
  const REGISTRATION_CONFIRMATION_URL = `${API_URL}/activatekontanlab.php`

  const [respon, setRespon] = useState({header: "waiting...", detail: ""});

  const query = useQuery();
  const confirmCode = query.get('confirm');

  useEffect(() => {
    var params = new URLSearchParams();
    params.append('k', confirmCode? confirmCode : "");

    // Make AJAX POST request using Axios
    axios.post(REGISTRATION_CONFIRMATION_URL, params)
      .then(response => {
        if(response.data.success===true)
            {
                setRespon({header: "Confirmation Successful", detail: "You can now login to your account"})
            }
        else
            {
                setRespon({header: "Confirmation Fail", detail: "You need to check your email or contact administrator"})
            }
      })
      .catch(error => {
        // Handle error
        console.error('Confirmation failed:', error);
      });
  }, [confirmCode]); // Only re-run effect if confirmCode changes


  return (
    
    <div className='d-flex flex-column flex-root'>
        <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>{respon.header}</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4 mt-10'>{respon.detail}</div>
            {/* end::Link */}
        </div>
        <div className='text-center'>
            <Link to='/auth/login'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                >
                    Continue to Login Page 
                </button>
            </Link>
        </div>
     </div>
  )
}

export {RegistrationConfirmation}
