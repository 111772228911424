import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as auth from './redux/AuthRedux';

const API_URL = process.env.REACT_APP_API_URL || 'api';

export function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await axios.post(`${API_URL}/logout.php`);
        dispatch(auth.logout());
        history.push('/auth/login');
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    };

    logoutUser();
  }, [dispatch, history]);

  return null;
}




