import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import CaseListPage from '../modules/documents/CaseListPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const DocumentListPage = lazy(() => import('../modules/documents/DocumentListPage'))
  const NotesListPage = lazy(() => import('../modules/notes/NotesListPage'))
  const NotePage = lazy(() => import('../modules/notes/NotePage'))
  const UserPage = lazy(() => import('../modules/notes/UserPage'))
  const WorkshopPage = lazy(() => import('../modules/profile/WorkshopPage'))
  const SubscriptionPage = lazy(() => import('../modules/subscription/SubscriptionPage'))
  const FeedbackPage = lazy(() => import('../modules/feedback/FeedbackPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route
          path='/documents'
          render={(props) => <DocumentListPage {...props} documentType='peraturan' />}
        />
        <Route
          path='/kasuspajak'
          render={(props) => <CaseListPage {...props} documentType='kasuspajak' />}
        />
        <Route path='/notes/:catatanId' component={NotePage} />
        <Route path='/notes' component={NotesListPage} />
        <Route path='/jadwal' component={WorkshopPage} />
        <Route path='/koleksiaturan' component={ChatPage} />
        <Route path='/subscription' component={SubscriptionPage} />
        <Route path='/feedback' component={FeedbackPage} />
        <Route path='/koleksiaturan2' component={AccountPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={WizardsPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/profile' component={UserPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
