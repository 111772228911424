/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Link, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL || 'api'
  const RESET_PASSWORD_URL = `${API_URL}/resetpassword.php`
  const UPDATE_PASSWORD_URL = `${API_URL}/updatepassword.php`

  const [respon, setRespon] = useState({ header: "waiting...", detail: "" });
  const [showForm, setShowForm] = useState(false);
  const [tokenForm, setTokenForm] = useState(null);
  const [jwtForm, setJWTForm] = useState(null);
  const [pActivityID, setPActivityID] = useState(null);

  const query = useQuery();
  const token = query.get('confirm');
  const t = query.get('t');

  useEffect(() => {
    var params = new URLSearchParams();
    params.append('token', token ? token : "");
    params.append('t', t ? t : "");

    // Make AJAX POST request using Axios
    axios.post(RESET_PASSWORD_URL, params)
      .then(response => {
        console.log(response);
        if (response.data.success === true) {
          setJWTForm(response.data.j);
          setTokenForm(response.data.t);
          setPActivityID(response.data.p_activity_id);
          setRespon({ header: "Confirmation Successful", detail: "You can now reset your password" })
          setShowForm(true);
        } else {
          setRespon({ header: "Confirmation Fail", detail: "You need to check your email or contact administrator" })
          setShowForm(false);
        }
      })
      .catch(error => {
        // Handle error
        console.error('Confirmation failed:', error);
      });
  }, [token, t]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(50, 'Maximum 50 characters')
        .matches(
          /^(?=(.*[a-z){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,50}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character'
        )
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
    }),
    onSubmit: values => {
      //TODO list
      const params = new URLSearchParams();
      params.append('t', tokenForm ? tokenForm : "");
      params.append('j', jwtForm ? jwtForm : "");
      params.append('p_activity_id', pActivityID ? pActivityID : "");
      params.append('password', values.password);

      axios.post(UPDATE_PASSWORD_URL, params)
        .then(response => {
          if (response.data.success === true) {
            setRespon({ header: "Reset Successful", detail: "You can now login with your new password" });
            setShowForm(false);
          } else {
            setRespon({ header: "Reset Failed", detail: "Please try again or contact administrator" });
            setShowForm(false);
          }
        })
        .catch(error => {
          console.error('Password reset failed:', error);
          setRespon({ header: "Reset Failed", detail: "Please try again or contact administrator" });
        });
    }
  });

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{respon.header}</h1>
        <div className='text-gray-400 fw-bold fs-4 mt-10'>{respon.detail}</div>
      </div>
      <div className='d-flex flex-column flex-center'>
        {showForm &&
          <form onSubmit={formik.handleSubmit} className='w-50'>
            <div className='form-group mb-10'>
              <label className='form-label' htmlFor='password'>New Password</label>
              <input
                type='password'
                id='password'
                name='password'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className='text-danger'>{formik.errors.password}</div>
              ) : null}
            </div>
            <div className='form-group mb-10'>
              <label className='form-label' htmlFor='confirmPassword'>Confirm Password</label>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className='text-danger'>{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
            <div className='text-center'>
              <button type='submit' className='btn btn-lg btn-primary fw-bolder'>
                Reset Password
              </button>
            </div>
          </form>
        }
        <div className='text-center mt-10'>
          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Continue to Login Page
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export { ResetPassword }
