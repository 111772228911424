import React, {useState, useEffect, useRef} from 'react'

import {
    TermsAndConditionsProps
} from './AuthCustomTypes'

import {UserModel} from '../models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

const TermsAndConditionsModal: React.FC<TermsAndConditionsProps> = ({
    showModalTermsAndConditions,
    onTCCloseButton
}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${user.accessToken}`, // Include the token as Bearer token in the Authorization header
    },
  }

  return (
    <>
      <div
        className={`modal fade ${showModalTermsAndConditions ? 'show' : ''}`}
        style={{
          display: 'block',
          transform: showModalTermsAndConditions ? 'translateY(0%)' : 'translateY(-100%)',
          backgroundColor: 'rgba(80, 80, 80, 0.7)',
        }}
      >
        {
          console.log("ini di modall bagian jsx...." + showModalTermsAndConditions)
        }
        <div
          className='modal-dialog modal-xl modal-dialog-centered'
          role='document'
          style={{width: '90%'}}
        >
          <div className='modal-content'>
            <div className='modal-header m-0 p-4 mb-0'>
                <h1 className='mt-1' style={{color: '#555'}}>
                    Terms and Conditions
                </h1>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={onTCCloseButton}
              >
              </div>
            </div>
            <div className='modal-body'>
              <div className='container p-0 mb-5'>
              <h2 dir="ltr">(Syarat &amp; Ketentuan Penggunaan www.kontanlab.id)</h2>
                <h3 dir="ltr">PERJANJIAN PENGGUNAAN LAYANAN SITUS</h3>
                <p dir="ltr">Perjanjian Penggunaan Layanan Situs www.kontanlab.id ("Perjanjian") antara Pengguna sebagai pihak yang menggunakan situs www.kontanlab.id dan https://tax.kontanlab.id/ ("Pengguna") dan KontanLab.id sebagai pengelola situs www.kontanlab.id dan https://tax.kontanlab.id/, ini memuat syarat-syarat dan ketentuan penggunaan layanan situs www.kontanlab.id dan https://tax.kontanlab.id/ ("Situs KontanLab") yang berlaku bagi Pengguna untuk dapat menggunakan Situs KontanLab. KontanLab.id selanjutnya dapat disebut juga dengan istilah "Kami".</p>
                <p dir="ltr">Mohon untuk membaca dengan hati-hati Perjanjian ini. Anda harus membaca, memahami, menerima dan menyetujui semua persyaratan dan ketentuan dalam Perjanjian ini sebelum menggunakan Situs KontanLab dan/atau menerima konten yang terdapat di dalamnya.</p>
                <p dir="ltr">Dengan melanjutkan akses terhadap Situs KontanLab, Anda menyetujui persyaratan dan ketentuan Kami, dan oleh karena itu menyetujui untuk terikat dalam suatu Perjanjian dengan Kami dan oleh karenanya Anda menyatakan persetujuan untuk dapat menerima layanan dan akses atas seluruh konten yang terdapat dalam Situs KontanLab.</p>
                <p dir="ltr">Jika Anda tidak menerima dan menyetujui Perjanjian ini, Anda tidak diperkenankan untuk mengakses lebih lanjut Situs KontanLab dan dipersilahkan untuk meninggalkan Situs KontanLab.</p>
                <p dir="ltr">Setiap kegiatan terkait dengan penggunaan situs, maka baik penyelenggara, pengguna dalam hal ini termasuk juga adalah penjual (merchant) dan pembeli, dilindungi secara hukum melalui ketentuan peraturan perundang-undangan yang berlaku termasuk namun tidak terbatas pada peraturan tentang Informasi dan Teknologi, Hak Kekayaan Intelektual, dan terhadap segala bentuk perikatan yang timbul dari segala aktifitas di Situs KontanLab telah memenuhi kaidah dan syarat sahnya suatu perikatan sebagaimana yang tercantum dalam Kitab Undang-Undang Hukum Perdata Indonesia.&nbsp;</p>
                <p dir="ltr">&nbsp;</p>
                <h3 dir="ltr">PENDAHULUAN</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Perjanjian ini diatur dan diinterpretasikan berdasarkan Hukum Republik Indonesia (Indonesia). Pihak-pihak yang disebutkan dalam Perjanjian ini dengan ini sepakat untuk tunduk kepada pengadilan di Indonesia.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dalam Perjanjian ini yang dimaksud dengan "Pengguna" adalah orang perseorangan, baik warga negara Indonesia, warga negara asing, maupun badan hukum dengan kemampuan menggunakan komputer, jaringan, komputer dan/atau media elektronik lainnya mampu untuk mengakses Situs KontanLab sesuai dengan keperluannya. Dalam hal ini termasuk juga Pengguna yang telah mendaftarkan diri pada Situs KontanLab sebagai Pengguna Terdaftar (Member KontanLab) atau Pengguna tersebut membayar untuk layanan tertentu yang mungkin disediakan oleh Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dengan mengakses atau menggunakan Situs KontanLab ini, Pengguna yang termasuk dalam kategori Pengguna sebagaimana disebutkan dalam poin 1.2. di atas secara sadar dan tanpa paksaan dari pihak manapun menyatakan diri setuju untuk menerima semua syarat dan ketentuan yang tercantum dalam Perjanjian ini. Sebagai Pengguna, Pengguna terikat dengan syarat dan ketentuan dalam Perjanjian yang berlaku dalam hal Pengguna mengakses atau menggunakan Situs KontanLab ini. Jika Pengguna tidak menerima semua syarat dan ketentuan dalam Perjanjian ini, Pengguna diharuskan untuk langsung meninggalkan Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami dapat mengubah atau memperbarui Perjanjian ini setiap waktu dengan mencantumkan Perjanjian yang telah diubah atau diperbarui di dalam Situs KontanLab dan persyaratan dan ketentuan yang telah diubah dan diperbaharui akan segera berlaku setelah persyaratan dan ketentuan yang telah diubah dan diperbaharui dicantumkan oleh Situs KontanLab, dan Pengguna diwajibkan untuk&nbsp; membaca persyaratan dan ketentuan&nbsp; dalam Perjanjian dari waktu ke waktu karena Perjanjian ini dapat berubah sewaktu waktu tanpa adanya pemberitahuan kepada Pengguna dan dianggap telah menyetujui perubahan atau pembaruan tersebut apabila setelah dicantumkan di dalam Situs KontanLab dan Pengguna terus menggunakan Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dalam Situs KontanLab akan atau telah terdapat sambungan atau hyperlink (Pranala) yang terhubung ke situs milik pihak ketiga yang terdapat dalam Pranala di luar Situs KontanLab dan Kami dengan ini menyatakan tidak bertanggung jawab atas isi Pranala di luar Situs KontanLab atau situs yang disediakan atau dipasang oleh pihak ketiga, baik yang dimasukkan oleh pengguna yang tidak disebutkan namanya atau oleh penyedia isi yang memperoleh pembayaran, atau bukan dibuat oleh kami. Dengan ditampilkannya Isi Pihak Ketiga dalam Situs KontanLab, tidak berarti bahwa Kami maupun pihak terafiliasi, pejabat, petugas atau pegawainya menjalin hubungan kerja sebagai agen penjualan dengan pihak ketiga tersebut. Isi Pihak Ketiga sepenuhnya merupakan tanggung jawab pihak penyedia isi. Kami tidak menjamin bahwa semua isi pihak ketiga akurat, tidak melanggar susila, absah atau adalah yang sesungguhnya, dan tidak bertanggung jawab jika Pengguna menganggap bahwa isi pihak ketiga tersebut dapat dipercaya isinya. Selain itu Kami tidak bertanggung jawab atas kegiatan yang dilakukan oleh Pengguna dan tidak dapat dimintai pertanggungjawaban oleh siapapun berkaitan dengan kerugian yang diderita pihak lain sebagai akibat perbuatan Pengguna.</p>
                </li>
                </ol>
                <h3 dir="ltr">KETENTUAN UMUM PENGGUNAAN SITUS</h3>
                <p dir="ltr">Saat mengunjungi dan menggunakan Situs KontanLab ini, termasuk setiap fitur dan layanannya, Setiap Pengguna wajib untuk mematuhi ketentuan Pengguna Situs berikut ini:</p>
                <p dir="ltr">&nbsp;</p>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna minimum berusia 17 Tahun, atau yang telah memiliki persyaratan yang diakui secara hukum termasuk dalam kategori Pengguna yang sudah dewasa / terlepas dari Hukum Perlindungan Anak.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna sepakat untuk tidak menyalin, menggunakan atau mengunduh semua informasi, tulisan, gambar, rekaman video, direktori, dokumen, database atau iklan yang ada di Situs KontanLab atau yang diperoleh melalui situs https://tax.kontanlab.id/ dengan tujuan apapun termasuk dan tidak terbatas pada diantaranya menjual kembali atau menyebarkan kembali isi Situs KontanLab dan https://tax.kontanlab.id/, menjalankan usaha untuk menyaingi Situs KontanLab atau memanfaatkan Situs KontanLab untuk keperluan komersial di luar kegiatan transaksi dengan Situs KontanLab. Pengguna tidak diperbolehkan mengambil isi Situs KontanLab secara sistematis untuk membuat atau menyusun, baik secara langsung maupun tidak langsung, koleksi, kompilasi, database atau direktori (baik menggunakan perangkat otomatis ataupun proses manual) tanpa izin tertulis dari Kami. Selain itu Pengguna tidak diperkenankan menggunakan isi atau bahan tersebut di atas untuk tujuan apa pun yang tidak disebutkan di dalam Perjanjian ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Saat mengunjungi dan menggunakan Situs KontanLab, termasuk setiap fitur dan layanannya, Setiap Pengguna tidak diperkenankan untuk:</p>
                </li>
                <ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">melanggar setiap hukum yang berlaku (termasuk tetapi tidak terbatas pada peraturan mengenai pengawasan ekspor, perlindungan konsumen, persaingan tidak sehat, anti diskriminasi atau iklan palsu), hak-hak pihak lain baik hak intelektual, asasi, dan lainnya, dan aturan-aturan yang diatur pada Perjanjian ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">memberikan informasi dan konten yang salah, tidak akurat, bersifat menyesatkan, bersifat memfitnah, bersifat asusila, mengandung pornografi, bersifat diskriminasi atau rasis.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Menggunakan data, informasi, dan akun yang tidak benar dan yang bersifat sementara dalam akun Member KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">mengambil tindakan yang dapat mengacaukan sistem saran atau masukan dan atau peringkat (seperti menampilkan, mengimpor atau mengekspor informasi atau masukan dari situs luar) atau menggunakannya untuk tujuan yang tidak terkait dengan Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">menyebarkan spam, hal-hal yang tidak berasusila, atau pesan elektronik yang berjumlah besar, pesan bersambung.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">menyebarkan virus atau seluruh teknologi lainnya yang sejenis yang dapat merusak dan/atau merugikan Situs KontanLab, afiliasinya dan pengguna lainnya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">memasukkan atau memindahkan fitur pada Situs KontanLab tidak terkecuali tanpa sepengetahuan dan persetujuan dari Kami.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">menyimpan, meniru, mengubah, atau menyebarkan konten dan fitur Situs KontanLab, termasuk cara pelayanan, konten, hak cipta dan intelektual yang terdapat pada Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">mengambil atau mengumpulkan informasi dari pengguna lain, termasuk alamat email, tanpa sepengetahuan pengguna lain.</p>
                </li>
                </ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami berhak membatasi atau tidak memberikan akses, atau memberikan akses yang berbeda untuk dapat membuka Situs KontanLab dan fitur didalamnya kepada masing-masing Pengguna, atau mengganti salah satu fitur atau memasukkan fitur baru tanpa pemberitahuan sebelumnya. Setiap Pengguna sadar bahwa jika Situs KontanLab tidak dapat digunakan seluruhnya atau sebagian karena alasan apapun, maka usaha atau kegiatan apapun yang dilakukan Pengguna dapat terganggu. Setiap Pengguna dengan ini sepakat bahwa karena alasan apapun membebaskan Kami dari segala bentuk pertanggungjawaban terhadap Pengguna atau terhadap pihak ketiga jika yang bersangkutan tidak dapat menggunakan Situs KontanLab (baik karena gangguan, dibatasinya akses, dilakukannya perubahan fitur atau tidak dimasukkannya lagi fitur tertentu atau karena alasan lain); atau jika komunikasi atau transmisi tertunda, gagal atau tidak dapat berlangsung; atau jika timbul kerugian (secara langsung, tidak langsung) karena digunakannya atau tidak dapat digunakannya Situs KontanLab atau salah satu fitur di dalamnya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dengan terus melakukan akses atau terus menggunakan Situs KontanLab, Pengguna dianggap telah membaca, memahami dan menyetujui ketentuan Situs KontanLab tentang Privacy Policy yang mengatur masalah penggunaan informasi yang dimasukkan masing-masing Pengguna ke dalam Situs KontanLab. Pengguna menerima ketentuan ini serta tambahan atau setiap perubahan atau pembaharuannya. Pengguna memahami dan mengetahui secara sadar bahwa Kami dapat mengubah ketentuan tentang Kerahasiaan Pribadi ini sewaktu-waktu dan akan memasukkan versi terbarunya di Situs KontanLab. Dengan terus mengakses dan menggunakan Situs KontanLab, Pengguna dianggap menerima dan menyetujui ketentuan tentang Privacy Policy yang tercantum di Situs KontanLab pada saat digunakan.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna mengetahui dan menyetujui bahwa harga yang tercantum pada Situs KontanLab dapat mengalami perubahan secara sewaktu-waktu dan tanpa pemberitahuan terlebih dahulu.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Akses situs ini hanya diperkenankan untuk keperluan dan kepentingan belajar dan informasi terkait dengan layanan situs ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna tidak diperkenankan untuk mereproduksi, mendistribusikan, memajang, menjual, menyewakan, mengirimkan, membuat karya turunan, menerjemahkan, memodifikasi, merekayasa balik, membongkar, mengurai atau mengeksploitasi situs ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna tidak diperkenankan untuk memuat dan menerbitkan konten yang:</p>
                </li>
                <ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Melanggar hak cipta, paten, merek dagang, merek layanan, rahasia dagang, atau hak kepemilikan lainnya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Mengancam, tidak senonoh, pornografi atau bisa menimbulkan segala kewajiban hukum perdata atau pidana Indonesia atau hukum internasional.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Mengandung bug, virus, worm, pintu perangkap, trojan horse atau kode dan properti berbahaya lainnya.</p>
                </li>
                </ul>
                </ol>
                <p dir="ltr">&nbsp;</p>
                <h3 dir="ltr">PENGGUNA MEMBERSHIP (CUSTOMER DATA PRIVACY POLICY)</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Menjadi Pengguna yang mendaftarkan diri lewat proses registrasi / signup pada akun KontanLab.id/signup, selanjutnya akan disebut sebagai "Member KontanLab", tidak dipungut biaya / gratis.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dengan memilih untuk membuat akun sebagai member dari Situs KontanLab, Member KontanLab akan membuat sebuah nama akun (email, mencantumkan nomor whatsapp, dan password) ketika menyelesaikan proses registrasi.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Member KontanLab bertanggung jawab untuk menjaga kerahasiaan dan keamanan atas nama akun, nomor whatsapp, dan password serta bertanggung jawab sepenuhnya atas segala kegiatan yang diatasnamakan nama akun Member KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami menggunakan data Anda untuk melakukan hal-hal seperti menyediakan layanan, berkomunikasi dengan Anda, memecahkan masalah, mengamankan dari penipuan dan penyalahgunaan, meningkatkan dan memperbarui layanan kami, menganalisis cara orang menggunakan layanan kami, menayangkan iklan yang dipersonalisasi, dan sebagaimana diwajibkan oleh hukum atau diperlukan untuk keamanan dan integritas. kami menyimpan data Anda selama diperlukan untuk memenuhi tujuan pengumpulannya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami berhak sepenuhnya antara lain untuk membatasi, memblokir atau mengakhiri pelayanan dari suatu akun, melarang akses ke Situs KontanLab dan konten, layanan, dan memperlambat atau menghapus hosted content, dan mengambil langkah-langkah hukum untuk menjaga Member KontanLab atau pengguna lainnya jika Kami menganggap Member KontanLab atau pengguna melanggar hukum-hukum yang berlaku, melanggar hak milik intelektual dari pihak terkait, melakukan kecurangan, menggunakan data informasi, dan akun yang tidak benar dan bersifat sementara dalam akun Member KontanLab, atau melakukan suatu pelanggaran yang melanggar hal-hal yang tertera pada Perjanjian ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Bahwa Member KontanLab tidak diperkenankan menjual, berupaya menjual, menawarkan untuk menjual, memberikan, menyerahkan atau mengalihkan Akun, Identitas Pengguna atau Sandi kepada pihak ketiga tanpa sepengetahuan dan persetujuan tertulis sebelumnya dari Kami. Kami dapat menangguhkan atau menghentikan Akun Member KontanLab atau Akun pihak yang menerima pengalihan dari Member KontanLab yang dijual, ditawarkan untuk dijual, diberikan, diserahkan atau dialihkan dengan melanggar ketentuan dalam Pasal ini. Apabila dengan keterbatasan kemampuan Kami dalam mengidentifikasi pelanggaran ini, maka seluruh akibat, resiko adalah merupakan tanggung jawab dari Member KontanLab yang mengalihkan.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami selalu berupaya untuk menjaga layanan Situs aman dan berfungsi dengan baik, tetapi Kami tidak dapat menjamin operasi terus menerus atau akses ke Situs dapat selalu sempurna. Informasi dan data dalam Situs memiliki kemungkinan tidak terjadi secara real time. Pengguna dan Member KontanLab setuju bahwa Anda memanfaatkan layanan Situs KontanLab atas resiko pengguna sendiri, dan layanan Situs diberikan kepada Anda pada "SEBAGAIMANA ADANYA" dan "SEBAGAIMANA TERSEDIA". Kami tidak bertanggung jawab, dan Anda setuju untuk tidak menuntut Kami bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari :</p>
                </li>
                <ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Penggunaan atau ketidakmampuan Pengguna atau Member KontanLab dalam menggunakan layanan Situs.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kelalaian yang ditimbulkan oleh masing-masing Pengguna atau Member KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Virus atau perangkat lunak berbahaya lainnya (bot, script,hacking tool) yang diperoleh dengan mengakses, atau menghubungkan ke layanan Situs.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kerusakan pada perangkat keras Anda dari penggunaan setiap layanan Situs.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun Pengguna atau Member KontanLab.</p>
                </li>
                </ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Member KontanLab menyetujui untuk:</p>
                </li>
                <ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Segera memberitahukan kepada Kami setiap adanya dugaan penggunaan yang tidak sah / valid dengan pengatasnamaan nama akun Member Anda.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Memastikan bahwa Member KontanLab keluar (logout) dari akun pada setiap akhir dari aktivitas pada Situs KontanLab untuk menghindari kemungkinan terjadinya penyalahgunaan atas akun yang bersangkutan.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Memberikan hak untuk menggunakan informasi yang diberikan pada saat registrasi untuk mengirimkan pesan lewat email dan whatsapp untuk kegiatan edukasi dan promosi.</p>
                </li>
                </ul>
                </ol>
                <h3 dir="ltr">HAK DAN KEWAJIBAN PENGGUNA</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna berkewajiban untuk membayar penuh atas transaksi yang dilakukan sesuai dengan ketentuan transaksi dan pembayaran.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna bertanggung jawab atas seluruh hal yang dilakukan di Situs KontanLab diatasnamakan Pengguna tersebut.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna bertanggung jawab sepenuhnya jika Pengguna melakukan pelanggaran terhadap ketentuan-ketentuan yang telah dirincikan pada Perjanjian ini, dan menyetujui untuk melepaskan Kami beserta afiliasinya atas seluruh kerugian yang diakibatkan oleh pelanggaran yang dilakukan oleh Pengguna.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna berhak mendapatkan barang/produk yang sesuai dan telah dibayar penuh sebelumnya oleh Pengguna, tidak dapat dikembalikan atau dibatalkan secara sepihak oleh Pengguna.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna berhak mendapatkan barang/produk yang telah dibayar penuh sepanjang barang/produk tersebut dicantumkan dengan harga dan rabat yang benar dan tidak mengandung informasi yang salah termasuk tidak terbatas pada akibat kesalahan pengetikan maupun kesalahan sistem operasional Situs KontanLab.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna dilarang untuk melakukan kecurangan (fraud) yaitu suatu tindakan yang disengaja dengan cara apapun untuk mendapatkan manfaat atau keuntungan baginya atau pihak lainnya yang bekerja sama Pengguna, yang menyebabkan kerugian bagi Kami, mitra usaha Kami, dan pihak-pihak lainnya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Pengguna memiliki hak tertentu seputar penggunaan data Anda, termasuk kemampuan untuk tidak menerima email promosi, cookie, dan pengumpulan data Anda oleh pihak ketiga tertentu. Pengguna dapat memperbarui atau menghentikan akun Anda dari layanan KontanLab, dan juga menghubungi KontanLab untuk permintaan hak individu tentang data pribadi Anda. Orang tua yang yakin KontanLab secara tidak sengaja mengumpulkan data pribadi tentang anak mereka yang masih di bawah umur dapat menghubungi KontanLab untuk meminta bantuan menghapus informasi tersebut.</p>
                </li>
                </ol>
                <h3 dir="ltr">KETENTUAN PEMBAYARAN</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami bekerja sama dengan penyedia jasa perbankan/pembayaran yang terpercaya dalam menyediakan berbagai metode pembayaran yang dapat digunakan oleh Pengguna.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna berhak untuk memilih metode pembayaran yang telah disediakan oleh Kami, yang mana Pengguna merasa lebih nyaman dan mudah dalam bertransaksi dengan Situs Kontan Store (https://store.kontan.co.id/), di mana setiap biaya transaksi pembayaran akan ditangguhkan kepada pengguna tersebut.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna berkewajiban untuk membayar penuh atas pemesanan yang dilakukan dalam jangka waktu yang ditentukan oleh Kami spesifik untuk setiap metode pembayaran sebelum Kami dapat memproses lebih lanjut atas pemesanan Pengguna. Bilamana Pengguna belum melaksanakan pembayaran dalam kurun waktu yang ditentukan maka Kami berhak menyatakan bahwa pesanan telah dibatalkan oleh Pengguna tersebut.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Setiap Pengguna dapat mengklarifikasi transaksi pembayaran yang telah dilaksanakan secara langsung ke penyedia jasa pembayaran yang bekerja sama dengan Kami sesuai dengan syarat dan ketentuan yang berlaku.</p>
                </li>
                </ol>
                <p dir="ltr">&nbsp;&nbsp;</p>
                <h3 dir="ltr">LINKS</h3>
                <p dir="ltr">Situs ini dapat berisi link internet ke situs lainnya yang dimiliki dan dioperasikan oleh pihak ketiga. Perlu Anda ketahui, bahwa Situs KontanLab tidak bertanggung jawab terhadap pengoperasian ataupun konten yang terletak di situs tersebut.</p>
                <p dir="ltr">&nbsp;</p>
                <h3 dir="ltr">HAK MILIK INTELEKTUAL</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">www.kontanlab.id adalah situs belajar online yang dibangun oleh DQLab dan Kontan dan bersama-sama merupakan pemilik atau pemegang sah semua hak atas dan isi dalam Situs KontanLab. Situs KontanLab dan isinya mencakup hak milik intelektual yang dilindungi oleh undang-undang hak cipta dan undang-undang yang melindungi kekayaan intelektual lainnya yang berlaku di seluruh dunia. Semua hak milik dan hak milik intelektual atas Situs KontanLab ini dan isinya tetap pada Kami, afiliasinya atau pemilik lisensi isi situs ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Situs KontanLab, nama, dan ikon serta logo terkait merupakan merek dagang terdaftar di berbagai wilayah hukum dan dilindungi undang-undang tentang hak cipta, merek dagang atau hak milik kekayaan intelektual lainnya. Dilarang keras menggunakan, mengubah, atau memasang merek-merek tersebut di atas untuk kepentingan pribadi dan untuk mendeskriditkan www.kontanlab.id.</p>
                </li>
                </ol>
                <p dir="ltr">&nbsp;</p>
                <h3 dir="ltr">PEMBERITAHUAN</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Semua pemberitahuan atau permintaan informasi kepada atau tentang Kami akan diproses jika dibuat secara tertulis dan dikirimkan kepada: KontanLab.id, Gedung Kompas Gramedia lantai 3 unit 2,Jalan Palmerah Selatan No. 20-28, Jakarta Pusat, DKI Jakarta 10270</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Semua pemberitahuan atau permintaan kepada atau tentang Pengguna akan diproses jika diserahkan langsung, dikirimkan melalui kurir, surat tercatat, faksimili atau email ke alamat surat, faks atau alamat email yang diberikan oleh Pengguna kepada Kami atau dengan cara memasang pemberitahuan atau permintaan tersebut di satu tempat di Situs KontanLab yang dapat diakses oleh umum tanpa dikenai biaya. Pemberitahuan kepada Pengguna akan dianggap sudah diterima oleh Pengguna tersebut jika dan bila:</p>
                </li>
                <ul>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami dapat menunjukkan bahwa komunikasi itu, baik dalam bentuk fisik maupun elektronik, telah dikirimkan kepada Pengguna tersebut, atau</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami sudah memasang pemberitahuan tersebut di tempat di Situs KontanLab yang dapat diakses oleh umum tanpa dikenai biaya.</p>
                </li>
                </ul>
                </ol>
                <p dir="ltr">&nbsp;</p>
                <h3 dir="ltr">PENUTUP</h3>
                <ol>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Kami dan Pengguna merupakan hubungan independen dan tidak ada hubungan keagenan, kemitraan, usaha patungan, karyawan-perusahaan atau pemilik waralaba-pewaralaba yang akan dibuat atau dibuat dengan adanya Perjanjian ini.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Judul di dalam Perjanjian ini dibuat sebagai acuan saja, dan sama sekali tidak menetapkan, membatasi, menjelaskan atau menjabarkan apa yang ada atau tercakup dalam pasal tersebut.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Tidak dilaksanakannya hak Kami untuk menuntut hak Kami berdasarkan Perjanjian ini ataupun tidak diambilnya tindakan oleh Kami terhadap pelanggaran yang dilakukan oleh Pengguna terhadap Perjanjian ini tidak akan mengesampingkan atau tidak mengesampingkan hak Kami untuk mengambil tindakan terhadap pelanggaran serupa atau pelanggaran berikutnya.</p>
                </li>
                <li dir="ltr">
                <p dir="ltr" role="presentation">Dengan menggunakan layanan di Situs KontanLab maka Pengguna telah membaca, mengerti, mematuhi dan menyetujui Perjanjian ini dan seluruh syarat syarat dan ketentuan-ketentuan yang berlaku di Situs KontanLab.</p>
                </li>
                </ol>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' onClick={() => onTCCloseButton()}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditionsModal;
