import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TableCases} from './components/TableCases'
//import {Note} from './components/Note'

const notesBreadCrumbs: Array<PageLink> = [
  {
    title: 'Note',
    path: '/notes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface CaseListPageProps {
  documentType: string;
  // You can also add other route props like:
  // history: History;
  // location: Location;
  // match: Match;
}

const CaseListPage: React.FC<CaseListPageProps> = ({ documentType }) => {
  // Define any properties you want to pass to TableDocuments here
  const tableDocumentsProps = {
    className: 'card-xl-stretch mb-0',
    // Add more properties as needed
    documentType: documentType
  }

  return (
    <>
      {/*
      <ProfileHeader />
      */}
      <Switch>
        {/* New Route */}
        <Route path='/kasuspajak'>
          <div>
            <PageTitle>Koleksi Putusan Pengadilan Pajak</PageTitle>
          </div>
          <TableCases {...tableDocumentsProps} />
        </Route>
        
      </Switch>
    </>
  )
}

export default CaseListPage
