/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'
//import {KTSVG} from '../../../helpers'
//import {Dropdown1} from '../../content/dropdown/Dropdown1'
//import svgNotes from '../../../../app/modules/profile/components/projects/notes-science-svgrepo-com.svg';
import svgDocumentList from '../../../../app/modules/documents/components/media/documentListIcon.svg';

import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'

import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

type Props = {
  className: string
}

const ListAturanTerbaru: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>((state) => state.auth, shallowEqual) as UserModel
  //const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel
  //const items = [{id: "123", judul: "A"},{id: "234", judul: "B"}, {id: "345", judul: "C"}, {id: "456", judul: "D"}];

  const dispatch = useDispatch()

  const jumlahDokumen = useRef<number>();

  const [items, setItems] = useState([
    {
      document_id: "", 
      document_code: "", 
      document_title: "",
      document_description: ""
    }
  ]);

  // component did mount
  useEffect(() => {
   var params = new URLSearchParams();
   params.append('email', user.email);
   params.append('doctype', 'peraturan');

   const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${user.accessToken}` // Include the token as Bearer token in the Authorization header
    }
   };

  //return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, params, config)
   axios
      .post( process.env.REACT_APP_API_URL + "/documentlist.php", params, config)
      .then((response) => {
        //console.log("respon documentlist")
        //console.log(response.data)
        if(response.data&&response.data.error)
        {
          dispatch(auth.logout())
        }
        jumlahDokumen.current = response.data.totaldata
        setItems(response.data.dokumen)
      });
  }, []);

  return (
    <div className={`card shadow ${className} pb-5`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-1 mt-4'>
      
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'><img src={svgDocumentList} alt="SVG Image" width={25} className="me-2"/>Dokumen Aturan Terbaru</span>
          <span className='text-muted fw-bold fs-7'>Total: {jumlahDokumen.current} Aturan Pajak</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body d-flex flex-column pt-5 pb-2'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {items.length>0 && items.map((item, index) => (
          <div key={100+index} className='timeline-item'>
            {/* begin::Item */}
            {/* begin::Label */}
              {index%4===0 &&
               <div key={'timeline-label' + index} className='timeline-label fw-bolder text-danger'>#{index+1}</div>
              }
              {index%4===1 &&
                <div key={'timeline-label' + index} className='timeline-label fw-bolder text-warning'>#{index+1}</div>
              }
              {index%4===2 &&
                <div key={'timeline-label' + index} className='timeline-label fw-bolder text-success'>#{index+1}</div>
              }
              {index%4===3 &&
                 <div key={'timeline-label' + index} className='timeline-label fw-bolder text-primary'>#{index+1}</div>
              }
            {/* end::Label */}
            {/* begin::Badge */}
            <div key={'timeline-badges-' + index} className='timeline-badge'>
              {index%4===0 &&
              <i key={'timeline-badge' + index} className='fa fa-genderless text-danger fs-1'></i>
              }
              {index%4===1 &&
              <i key={'timeline-badge' + index} className='fa fa-genderless text-warning fs-1'></i>
              }
              {index%4===2 &&
              <i key={'timeline-badge' + index} className='fa fa-genderless text-success fs-1'></i>
              }
              {index%4===3 &&
              <i key={'timeline-badge' + index} className='fa fa-genderless text-primary fs-1'></i>
              }
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div key={item.document_id} className='fw-mormal timeline-content text ps-3'>
            <strong>{item.document_code}</strong><br/>{item.document_title}
            </div>
            {/* end::Text */}
            {/* end::Item */}
          </div>
          ))}
        </div>
        {/* end::Timeline */}
          <div className='d-flex flex-row-reverse col-md-12 col-xl-12 mt-auto mb-3'>
            <Link className='btn btn-primary btn-sm mt-2 mb-0' to="/documents">
              Lihat Selengkapnya
            </Link>
          </div>
        </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ListAturanTerbaru}
