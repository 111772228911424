import {Action, createSlice} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
//import {persistReducer} from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import { UserRegistrationStatusModel } from '../models/UserRegistrationStatusModel'
import {userLogin, getUserByToken, userRegister} from './AuthCRUD'

export interface AuthActionWithPayload<T> extends Action {
  payload: T
}

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  Reset: '[Reset] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

//Initial state untuk slide Auth ini dengan tipe UserModel, ada empat property yang harus diisi (password, email, jumlahAkses, sisaHari)
const initialAuthState: UserModel = {
  password: undefined,
  email: '',
  jumlahAkses: 0,
  sisaHari: 0,
  memberSince: '',
  lastLoginStatus: ''
}

//Membuat slice
export const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    register: (state, action: AuthActionWithPayload<UserRegistrationStatusModel>) => {
      state.lastRegisterStatus = action.payload.success;
      state.lastRegisterMessage = action.payload.message;
      return state;
    },
    
    logout: (state) => {
      return initialAuthState
    },
    
    reset: (state) => {
      return initialAuthState;
    },

    requestUser: (state) => {
      return state
    },

    setXeraticUser: (state, action: AuthActionWithPayload<UserModel>) => {
      //return action.payload 
      //return initialAuthState;
      //Setiap return akan mengakibatkan state baru terbentuk
      
      return {
        ...state,
        email: action.payload.email,
        lastLoginStatus: action.payload.lastLoginStatus,
        accessToken: action.payload.accessToken,
        password: undefined,
        username: action.payload.username,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        fullname: action.payload.fullname,
        pic: action.payload.pic,
        jumlahAkses: action.payload.jumlahAkses,
        memberSince: action.payload.memberSince,
        sisaHari: action.payload.sisaHari,
      } 
    },
  },
})

export default AuthSlice.reducer
//export const {login, logout, register, requestUser, setUser} = AuthSlice.actions
export const {reset, logout, register, requestUser, setXeraticUser: setUser} = AuthSlice.actions

//Bagian dari Redux Saga
export function* saga() {

  yield takeLatest(actionTypes.UserRequested, userRequested);
  yield takeLatest(actionTypes.Login, loginSaga); //ini yang digunakan untuk listen
  yield takeLatest(actionTypes.Reset, resetSaga); //ini yang digunakan untuk listen
  yield takeLatest(actionTypes.Register, registerSaga); //ini yang digunakan untuk listen
  
  function* resetSaga() {
    yield(put(reset()));    
  }

  //================================================================================================
  function* registerSaga(action: AuthActionWithPayload<UserModel>) {
    try {
      //lakukan login terlebih dahulu      
      const {data: dataRegister, status: statusRegister, config: configLogin} = //extract data, status, config dari userLogin (yang merupakan return dari axios.post)
        yield userRegister( 
          action.payload.email, 
          action.payload.firstname,
          action.payload.lastname,
          action.payload?.password ?? '' 
        ); //yield userRegister

      yield put(register(dataRegister));
  } catch (error: any) {
      const axiosError = error as AxiosError;
      // Handle errors
      if (axiosError.response) {
        if (axiosError.response.status === 401) {
          //console.log('Unauthorized. Please check your credentials.');
        } else {
          //console.log('An error occurred:', axiosError.response.data);
        }
      } else if (axiosError.request) {
        // The request was made but no response was received
        //console.error('No response received from the server.');
        //console.log('No response received from the server.');
      } else {
        // Something happened in setting up the request that triggered an error
        //console.error('Error setting up the request:', axiosError.message);
      }
      ////yield put(setUser({...initialAuthState, lastLoginStatus: 'error'}));
    }    
  } // function* registerSaga
  //================================================================================================

  //================================================================================================
  //Cara melakukan login di saga, listenernya ada di yield takeLatest(actionTypes.Login, loginSaga); 
  function* loginSaga(action: AuthActionWithPayload<UserModel>) {
    try {
        //lakukan login terlebih dahulu      
        const {data: dataLogin, status: statusLogin, config: configLogin} = //extract data, status, config dari userLogin (yang merupakan return dari axios.post)
          yield userLogin( 
            action.payload.email, 
            action.payload?.password ?? '' //ini karena definisi password ada undefined
          ); //yield userLogin

        //jika tidak error, maka lanjut cari data user
        const {data: dataUser, status: statusUser, config: configUser} =  yield getUserByToken( 
            dataLogin.email, 
            dataLogin.accessToken //ini karena definisi password ada undefined
          ); //yield userLogin
        
          //console.log("dataUser...");
          //console.log(dataUser);
        yield put(setUser({...dataUser, lastLoginStatus: 'success'}));
    } catch (error: any) {
        const axiosError = error as AxiosError;
        // Handle errors
        if (axiosError.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (axiosError.response.status === 401) {
            console.error('Unauthorized Kontan / DQLab login. Please check your credentials.');
          } else {
            console.error('A Kontan / DQLab  error occurred:', axiosError.response.data);
          }
        } else if (axiosError.request) {
          // The request was made but no response was received
          //console.error('No response received from the server.');
          console.log('No response received from the server.');
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error setting up the request:', axiosError.message);
        }
        yield put(setUser({...initialAuthState, lastLoginStatus: 'error'}));
    }    
  } //function* loginSaga
  //================================================================================================

  function* userRequested(action: AuthActionWithPayload<UserModel>) {
    const {data: user} = yield getUserByToken(
                action.payload?.email ? action.payload?.email : '', 
                action.payload.accessToken?  action.payload.accessToken : ''
              )

    yield put(setUser(user)) //send ke "named reducer action"
  }
}

